var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [!_vm.rows.records ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "7",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index,
      class: row.group_status.toLowerCase() == 'active' ? '' : 'inactive-row'
    }, [_c('td', [_c('span', [_vm._v(_vm._s(row.signup_date))]), _c('span', {
      class: row.group_status.toLowerCase() == 'active' ? 'id-status-active' : 'id-status-inactive'
    }, [_vm._v(_vm._s(row.group_status))]), _c('span', [_c('strong', [_vm._v("Code:")]), _vm._v(" " + _vm._s(row.group_code))])]), _c('td', [_c('h3', [_vm._v(_vm._s(row.group_name))]), _c('span', [_vm._v(_vm._s(row.group_contact_full_name))]), _c('span', [_c('strong', [_vm._v("Email:")]), _vm._v(" " + _vm._s(row.group_email) + " "), _c('valid-icon', {
      attrs: {
        "is-valid": row.is_email_valid,
        "className": "valid-icon"
      }
    })], 1), _c('span', [_c('strong', [_vm._v("Phone:")]), _vm._v(" " + _vm._s(_vm.formatGroupPhone(row.group_phone)) + " "), _vm.formatGroupPhone(row.group_phone) != 'N/A' ? _c('valid-icon', {
      attrs: {
        "is-valid": row.is_phone_valid,
        "className": "valid-icon"
      }
    }) : _vm._e()], 1), _c('span', [_c('strong', [_vm._v("Web Url:")]), _vm._v(" " + _vm._s(row.group_web ? "/" + row.group_web : "N/A") + " ")])]), _c('td', [_c('span', [_c('strong', [_vm._v(" " + _vm._s(row.group_ga_agent_full_name ? row.group_ga_agent_full_name : "N/A") + " ")])]), _c('span', [_vm._v(" " + _vm._s(row.group_ga_agent_code ? row.group_ga_agent_code : "N/A") + " ")])]), _c('td', [_vm._v(_vm._s(row.location))]), _c('td', [_c('span', [_vm._v(_vm._s(row.estimate_number))]), _c('span', [_c('strong', [_vm._v("Industry:")]), _vm._v(" " + _vm._s(row.emp_industry))])]), _c('td', [_c('ul', [_c('li', [_c('strong', [_vm._v("A:")]), _vm._v(" " + _vm._s(row.active_total))]), _c('li', [_c('strong', [_vm._v("W:")]), _vm._v(" " + _vm._s(row.withdraw_total))]), _c('li', [_c('strong', [_vm._v("T:")]), _vm._v(" " + _vm._s(row.termed_total))]), _c('li', [_c('strong', [_vm._v("Totals:")]), _vm._v(" " + _vm._s(_vm.getStatTotal(row.active_total, row.withdraw_total, row.termed_total)) + " ")])]), _vm.getStatTotal(row.active_total, row.withdraw_total, row.termed_total) > 0 ? [_c('router-link', {
      staticClass: "viewClient-btn",
      attrs: {
        "to": {
          name: 'MemberListByGroup',
          params: {
            groupId: _vm.encodedId(row.group_id)
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" View Clients ")])] : _vm._e()], 2), _c('td', [_c('div', {
      staticClass: "action-btn"
    }, [_c('custom-route-button', {
      attrs: {
        "routeLink": {
          name: 'EmployerGroupDetail',
          params: {
            groupId: _vm.encodedId(row.group_id)
          }
        },
        "title": "View/Edit Details",
        "iconClass": "fas fa-eye"
      }
    }), row.active_total + row.termed_total > 0 ? [_c('custom-button', {
      attrs: {
        "title": "Payment History",
        "iconClass": "fas fa-dollar-sign"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'payment-history-modal');
        }
      }
    }), _c('custom-button', {
      attrs: {
        "title": "View Invoice",
        "iconClass": "fas fa-file-invoice-dollar"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'download-invoice-modal');
        }
      }
    })] : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Assign Platforms",
        "iconClass": "fa fa-handshake-o"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'view-domain-modal');
        }
      }
    }), _c('custom-button', {
      attrs: {
        "title": "Benefit Store",
        "iconClass": "fas fa-shopping-cart"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.toBenefitStore(row.group_id);
        }
      }
    }), row.actions.show_default_otp ? _c('custom-button', {
      attrs: {
        "title": "View Default OTP",
        "iconClass": "fas fa-list"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'defaultOtpModal');
        }
      }
    }) : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Send Group Enrollment",
        "iconClass": "fas fa-envelope"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'send-group-enrollment-modal');
        }
      }
    }), _c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.ViewMsg,
        expression: "ViewMsg",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "custom-btn",
      attrs: {
        "title": "View msg"
      },
      on: {
        "click": function click($event) {
          return _vm.routeToViewMessage(row);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../../assets/images/view-msg.svg")
      }
    })])], 2)])]);
  })], 2)]), _c('payment-history-modal', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('download-invoice-modal', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('default-otp-modal', {
    attrs: {
      "row": _vm.row,
      "rowType": "G"
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('send-group-enrollment', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  }), _c('view-domain-modal', {
    attrs: {
      "row": _vm.row,
      "allDomains": this.allDomains
    },
    on: {
      "onCloseModal": function onCloseModal($event) {
        _vm.row = null;
      }
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Signup Date")]), _c('th', [_vm._v("Group Name / Main Contact")]), _c('th', [_vm._v("Group GA")]), _c('th', [_vm._v("Location")]), _c('th', [_vm._v("Est. # of Employees / Industry")]), _c('th', [_vm._v("Totals")]), _c('th', [_vm._v("Actions")])])]);
}]

export { render, staticRenderFns }