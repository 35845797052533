var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "view-domain-modal",
      "centered": "",
      "size": "sm",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": _vm.showDomain
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('div', {
    staticClass: "title-text"
  }, [_c('h5', [_c('strong', [_vm._v("Assign Platforms")])])]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.showDomain.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('div', {
          staticClass: "assign-platform"
        }, [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-1",
            "options": _vm.allDomains,
            "aria-describedby": ariaDescribedby
          },
          model: {
            value: _vm.selectedDomains,
            callback: function callback($$v) {
              _vm.selectedDomains = $$v;
            },
            expression: "selectedDomains"
          }
        })], 1)];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "row justify-content-end domain-update"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_c('button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.updateDomains
    }
  }, [_vm._v(" Update ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }