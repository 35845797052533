var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "send-group-enrollment-modal",
      "centered": "",
      "size": "md",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('div', {
    staticClass: "title-text"
  }, [_c('h5', [_c('strong', [_vm._v("Send Enrollment URL")])])]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.sendEnrollmentEmail.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('input-multi-select-tags', {
    attrs: {
      "vmodel": _vm.receiver,
      "label-name": "Emails:",
      "options": _vm.options,
      "rules": {
        required: false
      },
      "form-group-class": "form-group mb-3",
      "placeholder": "Emails",
      "labelKey": "name",
      "trackBy": "code",
      "options-list": _vm.optionsList,
      "is-taggable": true,
      "max-height": 200,
      "tagPlaceholder": "Press enter to add email"
    },
    on: {
      "update:vmodel": function updateVmodel($event) {
        _vm.receiver = $event;
      },
      "onAddTag": _vm.addReceiver
    }
  })], 1)]), _c('div', {
    staticClass: "row justify-content-end payment-download"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Send")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }