var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper empGroup-wrapper"
  }, [_c('div', {
    ref: "employerContainer",
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v(" Employers & Groups "), _c('div', {
    staticClass: "addnew__dropdown"
  }, [_c('b-dropdown', {
    ref: "addNewDropdown",
    attrs: {
      "text": "Add New"
    }
  }, [_c('div', [_c('b-form-group', {
    staticClass: "addnew__form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('div', {
          staticClass: "domain-checkboxes"
        }, _vm._l(_vm.domains, function (domain) {
          return _c('b-form-checkbox', {
            key: domain.id,
            staticClass: "domain-checkbox",
            attrs: {
              "value": domain.id,
              "aria-describedby": ariaDescribedby,
              "aria-label": domain.domain,
              "id": 'checkbox-' + domain.id
            },
            model: {
              value: _vm.selected,
              callback: function callback($$v) {
                _vm.selected = $$v;
              },
              expression: "selected"
            }
          }, [_vm._v(" " + _vm._s(domain.name) + " ")]);
        }), 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "button_registration"
  }, [_c('button', {
    staticClass: "addnew_submit_button",
    class: {
      disabled_button: !this.selected.length > 0
    },
    attrs: {
      "disabled": !this.selected.length > 0
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.groupRegistrationLink.apply(null, arguments);
      }
    }
  }, [_vm._v(" Begin Registration ")])])])], 1), _c('a', {
    staticClass: "registration-link",
    staticStyle: {
      "background": "#3bb54a !important"
    },
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openRegistrationLinkModel('sendEmailRegistrationLinkEmployer');
      }
    }
  }, [_vm._v(" Send Registration Link ")])]), _c('button', {
    staticClass: "clear-search",
    on: {
      "click": _vm.clearData
    }
  }, [_vm._v("Clear Search")])]), _c('div', {}, [_c('employer-group-list-search', {
    attrs: {
      "filters": _vm.filterQuery
    },
    on: {
      "update:filters": function updateFilters($event) {
        _vm.filterQuery = $event;
      },
      "onSearch": _vm.searchData
    }
  })], 1), _c('div', {
    staticClass: "employerTable-card"
  }, [_c('div', {
    staticClass: "top-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('employer-group-list-table', {
    attrs: {
      "allDomains": this.domains,
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('send-registration-link-email-employer-modal'), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }