<template>
  <div class="page-wrapper empGroup-wrapper">
    <div class="container-fluid" ref="employerContainer">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>
          Employers & Groups
          <div class="addnew__dropdown">
            <b-dropdown text="Add New" ref="addNewDropdown">
              <div>
                <b-form-group class="addnew__form">
                  <template v-slot="{ ariaDescribedby }">
                    <div class="domain-checkboxes">
                      <b-form-checkbox
                        v-for="domain in domains"
                        :key="domain.id"
                        v-model="selected"
                        :value="domain.id"
                        :aria-describedby="ariaDescribedby"
                        :aria-label="domain.domain"
                        :id="'checkbox-' + domain.id"
                        class="domain-checkbox"
                      >
                        {{ domain.name }}
                      </b-form-checkbox>
                    </div>
                  </template>
                </b-form-group>
              </div>
              <div class="button_registration">
                <button
                  :disabled="!this.selected.length > 0"
                  :class="{ disabled_button: !this.selected.length > 0 }"
                  class="addnew_submit_button"
                  @click.prevent="groupRegistrationLink"
                >
                  Begin Registration
                </button>
              </div>
            </b-dropdown>
          </div>
          <a
            href="javascript:void(0)"
            @click.prevent="
              openRegistrationLinkModel('sendEmailRegistrationLinkEmployer')
            "
            style="background: #3bb54a !important"
            class="registration-link"
          >
            Send Registration Link
          </a>
        </h1>

        <button class="clear-search" @click="clearData">Clear Search</button>
      </div>

      <div class="">
        <employer-group-list-search
          :filters.sync="filterQuery"
          @onSearch="searchData"
        />
      </div>
      <div class="employerTable-card">
        <div class="top-pagination">
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </div>
        <employer-group-list-table
          :allDomains="this.domains"
          :rows.sync="rows"
        />
        <send-registration-link-email-employer-modal />
        <pagination :rows.sync="rows" :filters="filterQuery" />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import EmployerGroupListTable from "./partials/EmployerGroupListTable.vue";
import EmployerGroupListSearch from "./partials/EmployerGroupListSearch.vue";
import SendRegistrationLinkEmailEmployerModal from "./modals/SendRegistrationLinkEmailEmployerModal";

export default {
  components: {
    EmployerGroupListTable,
    EmployerGroupListSearch,
    SendRegistrationLinkEmailEmployerModal,
  },
  name: "EmployerGroupList",
  data: () => ({
    rows: [],
    filterQuery: {
      group_code: "",
      group_name: "",
      group_email: "",
      agent_id: "",
      state: "",
      status: "",
      effective_date: "",
      signup_date_from: "",
      signup_date_to: "",
      industry: "",
      type: "",
    },
    domains: [],
    selected: [],
    allSelected: false,
  }),
  computed: {
    ...mapGetters({ rep: "rep" }),
  },
  methods: {
    toggleAll() {
      if (this.allSelected) {
        this.selected = this.domains.map((domain) => domain.id);
      } else {
        this.selected = [];
      }
    },
    groupRegistrationLink() {
      let selectedDomainIds = this.selected.join(",");
      let encodedDomainIds = btoa(selectedDomainIds);
      const groupRegistrationLink =
        this.$endpoint.GROUP_APP_URL +
        `/registration?repCode=${this.rep.code}&domainIds=${encodedDomainIds}`;
      window.open(groupRegistrationLink, "_blank");
      this.closeDropdown();
    },
    openRegistrationLinkModel(modelId) {
      this.$bvModal.show(modelId);
    },
    getDomain() {
      Api.get("v2/get-enrollment-site", {
        headers: {
          Authorization: process.env.VUE_APP_REG_API_KEY,
        },
      }).then((response) => {
        this.domains = response.data.data;
      });
    },
    closeDropdown() {
      this.$refs.addNewDropdown.hide(); // Method to close dropdown
    },
    clearData() {
      this.filterQuery = {
        group_code: "",
        group_name: "",
        agent_id: "",
        state: "",
        status: "",
        effective_date: "",
        signup_date_from: "",
        signup_date_to: "",
        industry: "",
      };
      this.getAll();
    },
    searchData(filters) {
      this.filterQuery = filters;
      this.getAll(this.filterQuery);
    },
    getAll(filters = []) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/get-all-employer-and-group?${filterUrl}`;
      } else {
        url = `v2/get-all-employer-and-group`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },

  created() {
    this.getAll();
    this.getDomain();
  },
};
</script>
<style src="@/assets/css/employer-groups.css"></style>

<style>
.addnew__dropdown {
  display: inline-block;
}
.addnew__dropdown ul {
  z-index: 1;
  min-width: 200px;
}
.addnew__dropdown .btn-secondary {
  background: #03c3ec !important;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: inherit;
  border: 0;
  font-weight: 600;
  padding: 6px 12px;
  margin-left: 10px;
  margin-top: 2px;
}
.button_registration .disabled_button {
  background: rgb(176, 176, 176) !important;
  opacity: 999;
  border-radius: 4px;
  color: #737070;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border: none;
}
.button_registration {
  margin-bottom: 0.5rem;
}
.addnew__form {
  padding: 10px;
  font-weight: 500;
}
.custom-control-input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.domain-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 10px;
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
}
.addnew_submit_button {
  background-color: #03c3ec;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
  color: #fff;
  padding: 0.5rem 1rem;
  /* min-width: 100%; */
  border: none;
  display: block;
  margin: auto;
  font-size: 14px;
}
.domain-checkboxes .custom-control-label::before {
  border: none;
  background: transparent;
}
</style>
