<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        <span v-html="computedLabel" />
      </label>
      <multiselect
        :name="labelName"
        v-model="computedInputData"
        :options="options"
        :custom-label="customLabel"
        :multiple="true"
        :searchable="true"
        :placeholder="computedPlaceholder"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :hide-selected="false"
        :show-no-results="false"
        @remove="removeHandler"
        @select="selectHandler"
        @input="toggleHandler"
        @focus="autoCompleteHandler"
        :class="selectClassName(validationContext)"
        :loading="isLoading"
        :options-limit="optionsLimit"
        :internal-search="localSearch"
        :local-search="localSearch"
        :showNoResults="true"
        @search-change="searchHandler"
        @open="openHandler"
        :taggable="isTaggable"
        @tag="addTag"
        :max-height="maxHeight"
        :tag-placeholder="tagPlaceholder"
      >
        <template slot="selection" slot-scope="{ values }" v-if="showSelections">
          <span class="multiselect__single" v-if="values.length">
            {{ values.includes("all") ? "All " : values.length }}
            Options Selected
          </span>
        </template>
        <template v-else slot="tag" slot-scope="{ option, remove }"
          ><span :class="tagClassName ? tagClassName : computedTagClassName">
            {{ generateTagName(option) }}
            <i
              tabindex="1"
              class="multiselect__tag-icon"
              @click="remove(option)"
            ></i>
          </span>
        </template>
      </multiselect>
      <div class="error-text">
        <span>{{
          errorField ? errorField[0] : validationContext.errors[0]
        }}</span>
      </div>
    </validation-provider>
    <slot name="info-div" />
  </b-form-group>
</template>
<script>
export default {
  name: "InputMultiSelectTags",
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    vmodel: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    formGroupClass: {
      type: String,
      required: false,
      default: "col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-2",
    },
    options: {
      required: true,
    },
    customLabel: {
      required: false,
    },
    optionsLimit: {
      required: false,
      default: 300,
    },
    labelKey: {
      required: false,
    },
    localSearch: {
      required: false,
    },
    isLoading: {
      required: false,
    },
    apiErrors: {
      // Object with server side error flags
      required: false,
      default: () => {},
    },
    apiErrorKey: {
      // key pointing to particular entry in apiError Object
      required: false,
      default: "",
    },
    trackBy: {
      required: false,
      default: "id",
    },
    optionsList: {
      required: true,
      default: () => [],
    },
    isTaggable: {
      default: false,
    },
    maxHeight: {
      default: 300,
    },
    tagPlaceholder: {
      default: "Please enter to select tags",
    },
    showSelections :{
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    isAll: false,
    allCount: 0,
    inputSelectData: [],
    tagClassName: "",
    currentSelected: "",
    currentRemoved: "",
  }),
  computed: {
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `Select ${this.labelName}`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function () {
        return this.vmodel;
      },
      set: function (value) {
        this.inputSelectData = value;
        this.$emit("update:vmodel", value ? value : []);
      },
    },
    computedTagClassName() {
      if (this.options.some((value) => value == "all")) {
        if (this.vmodel.length == this.options.length) {
          return "multiselect__tag_custom";
        }
      }
      return "multiselect__tag";
    },
    errorField() {
      return this.apiErrors
        ? this.apiErrors[this.apiErrorKey]
          ? this.apiErrors[this.apiErrorKey]
          : null
        : null;
    },
  },
  methods: {
    searchHandler(value) {
      this.$emit("search", value);
    },
    openHandler() {
      this.$emit("open");
    },
    selectClassName(validationContext) {
      let className = "";
      if (validationContext.errors[0]) {
        className = "invalid-input-block";
      } else if (validationContext.valid) {
        className = "valid-input-block";
      } else {
        className = "";
      }
      return `${className} ${this.inputClass}`;
    },
    autoCompleteHandler() {
      document
        .querySelector(".multiselect__input")
        .setAttribute("autocomplete", "off");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    toggleHandler(value) {
      if (this.isAll) {
        if (value.includes("all")) {
          this.$emit("update:vmodel", this.options);
          this.tagClassName = "multiselect__tag_custom";
        } else {
          this.$emit("update:vmodel", []);
          this.tagClassName = "multiselect__tag";
        }
      } else {
        this.tagClassName = "multiselect__tag";
        if (this.currentRemoved && this.currentRemoved != "all") {
          let selectedOptions = [];
          value.forEach((val) => {
            selectedOptions.push(val);
            if (val == "all") {
              selectedOptions.pop();
            }
          });
          this.$emit("update:vmodel", selectedOptions);
        }
      }
      this.$emit("onInput", value);
    },
    selectHandler(value) {
      this.isAll = value && value == "all" ? true : false;
      this.currentSelected = value;
    },
    removeHandler(value) {
      this.isAll = value && value == "all" ? true : false;
      this.currentRemoved = value;
      this.currentSelected = "";
    },
    generateTagName(option) {
      let name = this.optionsList.find((item) => item[this.trackBy] == option)[
        this.labelKey
      ];
      return name;
    },
    addTag(name) {
      this.$emit("onAddTag", name);
    },
  },
  watch: {
    vmodel: function (newValue) {
      //if vmodel is less than 1 set this v-model = []
      if (newValue.length < 1) {
        this.inputSelectData = [];
      } else {
        this.inputSelectData = newValue;
      }
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}
.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.valid-input-block > .multiselect__tags {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.multiselect__tag_custom {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
span.multiselect__tag_custom:not(:first-of-type) {
  display: none !important;
}
.error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
