<template>
  <b-modal
    id="download-invoice-modal"
    centered
    size="sm"
    v-model="show"
    no-close-on-backdrop
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="title-text">
      <h5><strong>Download Invoice</strong></h5>
    </div>
    <form @submit.prevent="downloadInvoice">
      <div class="row">
        <div class="col-md-12">
          <label>Select Date</label>
          <date-picker
            class="w-100"
            type="month"
            v-model="date"
            placeholder="Please Select Date"
            :append-to-body="appendDatepicker"
          ></date-picker>
        </div>
      </div>
      <div class="row justify-content-end payment-download">
        <div class="col-sm-6">
          <button type="submit" class="univ-btn">Download</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";

export default {
  props: {
    row: {
      required: true,
    },
  },
  data: () => {
    return {
      show: false,
      date: "",
      variants: ["close-btn"],
      appendDatepicker: false,
    };
  },
  methods: {
    downloadInvoice() {
      this.showTable = false;
      let url = `v2/group-invoice/render/${this.formattedDate(
        this.date
      )}?group_id=${this.row.group_id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let invoice = res.data.data;
          if (invoice.file) {
            window.open(invoice.file, "_blank");
          }
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },

    formattedDate(date) {
      return moment(date).endOf("month").format("YYYY-MM-DD");
    },
    closeModal() {
      this.date = '';
      this.$emit("onCloseModal");
      this.$bvModal.hide("download-invoice-modal");
    }
  },
};
</script>
<style scoped>
.payment-download {
  margin-top: 20px;
}
.title-text {
  margin-bottom: 20px;
}
.invoice-list {
  padding-top: 40px;
}
</style>
<style>
.modal-header,
.modal-footer {
  display: none;
}
.modal-content {
  border: 0px;
  border-radius: 10px;
}
.btn-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
