<template>
  <b-modal
    id="view-domain-modal"
    centered
    size="sm"
    v-model="show"
    no-close-on-backdrop
    @shown="showDomain"
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="title-text">
      <h5><strong>Assign Platforms</strong></h5>
    </div>
    <form @submit.prevent="showDomain">
      <div class="row">
        <div class="col-md-12">
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="assign-platform">
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedDomains"
                :options="allDomains"
                :aria-describedby="ariaDescribedby"
              ></b-form-checkbox-group>
            </div>
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-end domain-update">
        <div class="col-sm-12">
          <button type="submit" class="univ-btn" @click="updateDomains">
            Update
          </button>
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
export default {
  props: {
    row: {
      required: true,
    },
  },
  data: () => {
    return {
      show: false,
      variants: ["close-btn"],
      domains: [],
      allDomains: [],
      selectedDomains: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("onCloseModal");
      this.$bvModal.hide("view-domain-modal");
    },
    showDomain() {
      let url = `v2/group-personalized-site/list?group_id=${this.row.group_id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.domains = res.data.data;

          this.allDomains = res.data.data.map((domain) => ({
            text: domain.name,
            value: domain.id,
          }));
          this.selectedDomains = this.domains
            .filter((domain) => domain.checked === 1)
            .map((domain) => domain.id);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    updateDomains() {
      let url = `v2/group-personalized-site/store?group_id=${this.row.group_id}`;
      let data = {
        id: this.selectedDomains,
      };
      let loader = this.$loading.show();
      Api.put(url, data)
        .then((res) => {
          this.$notify(res.data.message, "success");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
          this.$bvModal.hide("view-domain-modal");
        });
    },
  },
};
</script>
<style scoped>
.domain-update {
  margin-top: 20px;
}
.title-text {
  margin-bottom: 20px;
}
</style>
<style>
.modal-header,
.modal-footer {
  display: none;
}
.modal-content {
  border: 0px;
  border-radius: 10px;
}
.btn-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.platform-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.assign-platform .bv-no-focus-ring .custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.custom-control-label::before {
  background-color: transparent;
  border: none;
}
</style>
