<template>
  <!-- Modal -->
  <b-modal
    id="sendEmailRegistrationLinkEmployer"
    modal-class="employer-group-details-modal"
    centered
    size="lg"
    hide-footer
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <b-container fluid>
      <div class="view-employer-header-content mt-3">
        <h5><strong>Send Registration Link To User</strong></h5>
        <div class="addMessage-form mt-3">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(sendEmailGroup)"
              ref="sendEmailRegistrationLink"
            >
              <div class="form-group row">
                <input-text
                  :rules="{ required: true, neverbounce: true }"
                  labelFor="email"
                  labelName="Email"
                  inputName="email"
                  :vmodel.sync="form.email"
                  formGroupClass="col-xxl-6 mb-3"
                  inputType="email"
                />

                <input-text
                  :rules="{ required: true }"
                  labelFor="subject"
                  labelName="Subject"
                  inputName="subject"
                  :vmodel.sync="form.subject"
                  formGroupClass="col-xxl-6 mb-3"
                />

                <div class="col-xxl-12 mb-3">
                  <validation-provider
                    name="Message"
                    v-slot="{ errors }"
                  >
                    <label>
                      Message
                    </label>
                    <ckeditor
                      :editor="editor"
                      v-model="form.message"
                      :config="editorConfig"
                    />
                    <div class="invalid-block invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-xxl-5 mb-4">
                  <b-button class="univ-btn" type="submit" :disabled="isSend">
                    <template v-if="isSend"> Sending ... </template>
                    <template v-else> Send </template>
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "SendRegistrationLinkEmailModal",
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    form: {
      email: "",
      message: "",
      subject: "",
      is_group_link: 1
    },
    isSend: false,
  }),
  methods: {
    reset() {
      this.form = {
        email: "",
        message: "",
        subject: "",
        is_group_link: 1
      };
      this.$refs.observer.reset();
    },
    closeModal() {
      this.reset();
      this.$emit("onCloseModal");
      this.$bvModal.hide("sendEmailRegistrationLinkEmployer");
    },
    sendEmailGroup() {
      let loader = this.$loading.show({
        container: this.$refs.sendEmailRegistrationLink,
      });
      this.isSend = true;
      const url = `v2/send-registration-link`;
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    },
  },
};
</script>
<style>

.employer-group-details-modal .modal-content {
  border: 0px;
  border-radius: 10px;
}
.employer-group-details-modal .modal-content .modal-body {
  border: 0px;
  border-radius: 10px;
}

.view-employer-header {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.view-employer-header-content a {
  color: #4db7e9;
}



.ck-editor__editable {
  min-height: 200px;
}

.addMessage-form label{
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
</style>
