<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Signup Date</th>
          <th>Group Name / Main Contact</th>
          <th>Group GA</th>
          <th>Location</th>
          <th>Est. # of Employees / Industry</th>
          <th>Totals</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!rows.records">
          <td colspan="7" align="center">No records found.</td>
        </tr>
        <tr
          v-for="(row, index) in rows.records"
          :key="index"
          :class="
            row.group_status.toLowerCase() == 'active' ? '' : 'inactive-row'
          "
        >
          <td>
            <span>{{ row.signup_date }}</span>
            <span
              :class="
                row.group_status.toLowerCase() == 'active'
                  ? 'id-status-active'
                  : 'id-status-inactive'
              "
              >{{ row.group_status }}</span
            >
            <span><strong>Code:</strong> {{ row.group_code }}</span>
          </td>
          <td>
            <h3>{{ row.group_name }}</h3>
            <span>{{ row.group_contact_full_name }}</span>
            <span>
              <strong>Email:</strong>
              {{ row.group_email }}
              <valid-icon
                :is-valid="row.is_email_valid"
                className="valid-icon"
              />
            </span>
            <span>
              <strong>Phone:</strong>
              {{ formatGroupPhone(row.group_phone) }}
              <valid-icon
                v-if="formatGroupPhone(row.group_phone) != 'N/A'"
                :is-valid="row.is_phone_valid"
                className="valid-icon"
              />
            </span>
            <span>
              <strong>Web Url:</strong>
              {{ row.group_web ? "/" + row.group_web : "N/A" }}
            </span>
          </td>
          <td>
            <span>
              <strong>
                {{
                  row.group_ga_agent_full_name
                    ? row.group_ga_agent_full_name
                    : "N/A"
                }}
              </strong>
            </span>
            <span>
              {{ row.group_ga_agent_code ? row.group_ga_agent_code : "N/A" }}
            </span>
          </td>
          <td>{{ row.location }}</td>
          <td>
            <span>{{ row.estimate_number }}</span>
            <span><strong>Industry:</strong> {{ row.emp_industry }}</span>
          </td>
          <td>
            <ul>
              <li><strong>A:</strong> {{ row.active_total }}</li>
              <li><strong>W:</strong> {{ row.withdraw_total }}</li>
              <li><strong>T:</strong> {{ row.termed_total }}</li>
              <li>
                <strong>Totals:</strong>
                {{
                  getStatTotal(
                    row.active_total,
                    row.withdraw_total,
                    row.termed_total
                  )
                }}
              </li>
            </ul>

            <template
              v-if="
                getStatTotal(
                  row.active_total,
                  row.withdraw_total,
                  row.termed_total
                ) > 0
              "
            >
              <router-link
                :to="{
                  name: 'MemberListByGroup',
                  params: { groupId: encodedId(row.group_id) },
                }"
                target="_blank"
                class="viewClient-btn"
              >
                View Clients
              </router-link>
            </template>
          </td>
          <td>
            <div class="action-btn">
<!--              <custom-route-button
                :routeLink="{
                  name: 'EmployerGroupPlans',
                  params: { groupId: encodedId(row.group_id) },
                }"
                title="Assign Plan"
                iconClass="fas fa-plus"
              />-->
              <!-- <router-link
                to="/group-rep-display-setting"
                v-b-tooltip.hover
                title="Display Setting"
                ><i class="fas fa-desktop"></i
              ></router-link> -->
              <custom-route-button
                :routeLink="{
                  name: 'EmployerGroupDetail',
                  params: { groupId: encodedId(row.group_id) },
                }"
                title="View/Edit Details"
                iconClass="fas fa-eye"
              />

              <template v-if="row.active_total + row.termed_total > 0">
                <custom-button
                  title="Payment History"
                  iconClass="fas fa-dollar-sign"
                  @onClicked="openModal(row, 'payment-history-modal')"
                />
                <custom-button
                  title="View Invoice"
                  iconClass="fas fa-file-invoice-dollar"
                  @onClicked="openModal(row, 'download-invoice-modal')"
                />
              </template>
              <custom-button
                title="Assign Platforms"
                iconClass="fa fa-handshake-o"
                @onClicked="openModal(row, 'view-domain-modal')"
              />
              <custom-button
                title="Benefit Store"
                iconClass="fas fa-shopping-cart"
                @onClicked="toBenefitStore(row.group_id)"
              />
              <custom-button
                v-if="row.actions.show_default_otp"
                title="View Default OTP"
                iconClass="fas fa-list"
                @onClicked="openModal(row, 'defaultOtpModal')"
              />

              <custom-button
                title="Send Group Enrollment"
                iconClass="fas fa-envelope"
                @onClicked="openModal(row, 'send-group-enrollment-modal')"
              />
              <button
                title="View msg"
                class="custom-btn"
                v-b-tooltip.hover="ViewMsg"
                @click="routeToViewMessage(row)"
              >
                <img src="../../../assets/images/view-msg.svg" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <payment-history-modal :row="row" @onCloseModal="row = null" />
    <download-invoice-modal :row="row" @onCloseModal="row = null" />
    <default-otp-modal :row="row" rowType="G" @onCloseModal="row = null" />
    <send-group-enrollment :row="row" @onCloseModal="row = null" />
    <view-domain-modal
      :row="row"
      :allDomains="this.allDomains"
      @onCloseModal="row = null"
    />
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import PaymentHistoryModal from "../modals/PaymentHistoryModal.vue";
import DownloadInvoiceModal from "../modals/DownloadInvoiceModal.vue";
import ViewDomainModal from "../modals/ViewDomainModal.vue";
import DefaultOtpModal from "@/components/ui/DefaultOtpModal.vue";
import SendGroupEnrollment from "../modals/SendGroupEnrollment.vue";
export default {
  name: "EmployerGroupListTable",
  components: {
    PaymentHistoryModal,
    ViewDomainModal,
    DownloadInvoiceModal,
    DefaultOtpModal,
    SendGroupEnrollment,
  },
  props: {
    rows: {
      required: true,
    },
    allDomains: {
      type: Array,
    },
  },
  data() {
    return {
      row: null,
    };
  },
  methods: {
    encodedId(id) {
      return btoa(id);
    },
    getStatTotal(activeTotal, withdrawTotal, termedTotal) {
      let total = 0;
      total =
        parseInt(activeTotal) + parseInt(withdrawTotal) + parseInt(termedTotal);
      return total;
    },
    formatGroupPhone(phone) {
      return Helper.formatPhoneNumber(phone)
        ? Helper.formatPhoneNumber(phone)
        : "N/A";
    },
    openModal(row, modalId) {
      this.row = row;
      this.$bvModal.show(modalId);
    },
    routeToViewMessage(row) {
      let app = this;
      let userInfo = {
        user_id: "",
        user_message_type: "",
      };
      userInfo.user_id = row.group_id;
      userInfo.user_message_type = "G";
      app.$store.commit("setViewMessageUserInfo", userInfo);
      app.$router.push("/view-msg");
    },
    toBenefitStore(id) {
      let url = `${this.$endpoint.BENEFIT_STORE_URL}/group-home?group_id=${btoa(
        id
      )}`;
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
.valid-icon {
  width: 2.8%;
}
</style>
