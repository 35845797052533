<template>
  <div class="row employergroup-card">
    <div class="employergroup-wrap">
      <form @submit.prevent="searchEmployerGroup">
        <div class="form-group row">
          <input-text
            labelFor="group_code"
            labelName="Group Name"
            :vmodel.sync="filters.group_name"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            inputClass="employergroupsearch-field"
          />
          <input-text
            labelFor="group_code"
            labelName="Group Code"
            :vmodel.sync="filters.group_code"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            inputClass="employergroupsearch-field"
          />

          <input-text
            labelFor="group_email"
            labelName="Email"
            :vmodel.sync="filters.group_email"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            inputClass="employergroupsearch-field"
          />
          <input-multi-select
            labelFor="status"
            labelName="Status"
            inputName="status"
            :vmodel.sync="filters.status"
            :options="statuses.map((type) => type.value)"
            :custom-label="(opt) => statuses.find((x) => x.value == opt).text"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            placeholder="Select Status"
          />
        </div>

        <div class="form-group row">
          <input-multi-select
            labelFor="state"
            labelName="State"
            inputName="state"
            :vmodel.sync="filters.state"
            :options="states.map((type) => type.value)"
            :custom-label="(opt) => states.find((x) => x.value == opt).text"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            placeholder="Select State"
          />

          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
            <label>Enrollment Date</label>
            <div class="enrollmentDate-picker">
              <date-picker
                @input="setToDate"
                input-class="employergroupsearch-field"
                placeholder="From"
                type="date"
                v-model="filters.signup_date_from"
                value-type="format"
                :disabled-date="
                  (date) => {
                    return disabledAfterCurrentDate(date, 'start');
                  }
                "
              ></date-picker>

              <date-picker
                input-class="employergroupsearch-field"
                placeholder="To"
                type="date"
                v-model="filters.signup_date_to"
                value-type="format"
                :disabled="disableDate"
                :disabled-date="
                  (date) => {
                    return disabledAfterCurrentDate(date, 'end');
                  }
                "
              ></date-picker>
            </div>
          </div>

          <input-multi-select
            labelFor="industry"
            labelName="Industry"
            inputName="industry"
            :vmodel.sync="filters.industry"
            :options="groupIndustries.map((type) => type.value)"
            :custom-label="
              (opt) => groupIndustries.find((x) => x.value == opt).title
            "
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            placeholder="Select Industry"
          />

          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4">
            <label>View Downline Clients For</label>
            <multiselect
              name="rep"
              v-model="filters.agent_id"
              placeholder="Select Rep"
              :options="reps.map((type) => type.id)"
              :custom-label="
                  (opt) => reps.find((x) => x.id == opt).first_name+' '+reps.find((x) => x.id == opt).last_name
                "
              :options-limit="100"
              :internal-search="false"
              :local-search="false"
              @search-change="searchHandler"
              @open="getReps"
            />
          </div>
           <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
            <label>Employers & Groups</label>
            <select class="form-select shadow-none" v-model="filters.type">
              <option value="">All</option>
              <option value="direct">Direct</option>
              <option value="downline">Downline</option>
            </select>
          </div>
        </div>

        <div class="row justify-content-md-end">
          <div class="col-xxl-2 col-xl-3">
            <button type="submit" class="univ-btn">Search</button>
          </div>
          <div class="col-xxl-2 col-xl-3">
            <button type="button" class="univ-btn" @click="exportGroup"><i class="fas fa-file-export" style="
    margin-right: 5px;
"></i> Export</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "EmployerGroupListSearch",
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    disableDate: true,
    reps: [],
    states: Helper.getStates(),
    statuses: [
      { text: "ALL", value: " " },
      { text: "ACTIVE", value: "1" },
      { text: "INACTIVE", value: "0" },
    ],
    groupIndustries: [],
  }),
  methods: {
    searchHandler(query) {
      if (query.length > 2) {
        this.getReps(query);
      }
    },
    getGroupIndustries() {
      let url = "v2/get-employer-industry";
      Api.get(url)
        .then((res) => {
          this.groupIndustries = res.data.data;
        })
        .catch(() => {
          console.log("Something is wrong.");
        });
    },
    getReps(query = null) {
      let url;
      const baseUrl = `v2/get-rep-downline?size=100`;
      if (query) {
        url = `${baseUrl}&rep_name=${query}`;
      } else {
        url = baseUrl;
      }
      Api.get(url)
        .then((res) => {
          this.reps = res.data.data;
        })
        .catch(() => {
          console.log("Failed to fetch groups");
        });
    },
    searchEmployerGroup() {
      this.$emit("onSearch", this.filters);
    },
    disabledAfterCurrentDate(date, type) {
      const formattedDate = new Date(new Date().setHours(0, 0, 0, 0));
      if (type == "end") {
        if (
          date <
          new Date(new Date(this.filters.enrollment_from).setHours(0, 0, 0, 0))
        ) {
          return true;
        }
      }
      return (
        date >
        formattedDate.setDate(formattedDate.getDate() - +(type == "start"))
      );
    },
    setToDate(date) {
      if (date) {
        this.disableDate = false;
      } else {
        this.filters.enrollment_to = "";
        this.disableDate = true;
      }
    },
   async exportGroup() {
      let url;
      if (this.filters) {
        let filterUrl = Helper.objToUrlParams(this.filters);
        url = this.$endpoint.EXPORT_GROUP_LIST_FORMATTED + `?${filterUrl}`;
      } else {
        url = this.$endpoint.EXPORT_GROUP_LIST_FORMATTED;
      }
      let loader = this.$loading.show();
      await Api
        .get(url)
        .then((res) => {
           window.open(res.data.data.file_url, "_blank");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created(){
    this.getGroupIndustries()
  }
};
</script>

<style scoped>
.form-select:focus{
  border-color:#03C3EC;
}
</style>