<template>
  <b-modal
    id="payment-history-modal"
    centered
    title="Payment History"
    v-model="show"
    no-close-on-backdrop
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="title-text">
      <h5><strong>Payment History</strong></h5>
    </div>
    <form @submit.prevent="downloadPaymentHistory">
      <div class="row">
        <div class="col-md-6">
          <label for="">From</label>
          <date-picker
            class="w-100"
            type="date"
            v-model="from_date"
            placeholder="Start Date"
            :append-to-body="appendDatepicker"
          ></date-picker>
        </div>
        <div class="col-md-6">
          <label for="">To</label>
          <date-picker
            class="w-100"
            v-model="to_date"
            type="date"
            placeholder="End Date"
            :append-to-body="appendDatepicker"
          ></date-picker>
        </div>
      </div>
      <div class="row justify-content-end payment-download">
        <div class="col-sm-4">
          <button type="submit" class="univ-btn">Download</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";

export default {
  props: {
    row: {
      required: true,
    },
  },
  data: () => {
    return {
      show: false,
      from_date: "",
      to_date: "",
      variants: ["close-btn"],
      appendDatepicker: false,
    };
  },
  computed: {},
  methods: {
    downloadPaymentHistory() {
      let url = `v2/generate-group-invoice-payment-history?from_date=${this.formattedDate(
        this.from_date
      )}&to_date=${this.formattedDate(this.to_date)}&group_id=${this.row.group_id}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          if (data.file_url) {
            window.open(data.file_url, "_blank");
          }
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          loader.hide();
        });
    },

    formattedDate(dt) {
      return moment(dt).format("YYYY-MM-DD");
    },
    closeModal() {
      this.from_date = '';
      this.to_date = '';
      this.$emit("onCloseModal");
      this.$bvModal.hide("payment-history-modal");
    }
  },
};
</script>
<style scoped>
.payment-download {
  margin-top: 20px;
}
.title-text {
  margin-bottom: 20px;
}
</style>
<style>
.modal-header,
.modal-footer {
  display: none;
}
.modal-content {
  border: 0px;
  border-radius: 10px;
}
.btn-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
